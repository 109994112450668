
import Select from '@/components/select/select.vue'
export default {
  props: {
    isSwitchLogo: false,
  },
  data(){
    return {
      // 导航菜单
      navList: [
        { id: 1, selected: false, link: '/', title: '首页' },
        { id: 2, selected: false, link: '/products', title: '产品中心' },
        { id: 3, selected: false, link: '/advantage', title: '技术优势' },
        { id: 4, selected: false, link: '/system', title: '品质体系' },
        { id: 5, selected: false, link: '/about', title: '关于我们' },
        { id: 6, selected: false, link: '/contact', title: '联系我们' }
      ],
      floatImgInfo: [
        {id:4, link: '/detail/jm', title:'精密模具设计制造', img:require('../assets/img/head_img_4.png')},
        {id:1, link: '/detail/yl', title:'医疗用品设计制造', img:require('../assets/img/head_img_1.jpg')},
        {id:2, link: '/detail/yt', title:'液态硅胶LSR成型技术', img:require('../assets/img/head_img_2.png')},
        {id:3, link: '/detail/ss', title:'双射及多色各类包胶技术', img:require('../assets/img/head_img_3.png')},
      ],
      floatList: [
        {id:1, link: '/detail/mj', title: '复合材料注塑成型'},
        {id:2, link: '/detail/gt', title: '固态硅胶及合成橡胶应用'},
        {id:3, link: '/detail/fs', title: '防水专家IP级防水方案'},
        {id:11, link: '/detail/js', title: '金属包胶埋入成型'},
        // {id:4, link: '/detail/mj', title: '硅橡胶多色包胶成型'},
        {id:5, link: '/detail/fpc', title: 'FPC 触摸屏面板/薄膜开关研发制造'},
        {id:6, link: '/detail/bm', title: '表面处理应用'},
        {id:7, link: '/detail/dq', title: '氮气辅助注塑成型'},
        {id:8, link: '/detail/yd', title: '液氮冷冻去毛边'},
        // {id:9, link: '/detail/g3d', title: '快速模具3D打印技术'},
        {id:10, link: '/detail/g3d', title: '高仿真材料3D打印'},
        {id:12, link: '/detail/jmdzypzz', title: '精密电子用品组装'},
      ],
      // 语言选项
      options: [
        { value: '简体中文', label: '简体中文' },
        { value: '繁體中文', label: '繁體中文' },
        { value: 'English', label: 'English'},
        // { value: '日本語', label: '日本語'}
      ],
      language: '简体中文',
      showFloat: false,
      isWhite: false
    }
  },
  watch:{
    language(newL){
      // console.log(this.$i18n.getLocaleCookie())
      if (newL === 'English') {
        window.location.href = 'http://www.win-industry.com/';
      }else if(newL === '繁體中文'){
        window.location.href = 'https://www.winindustry.com/tc/';
      }else if(newL === '日本語'){
        window.location.href = 'https://www.winindustry.com/jp/';
      }else{
        return false;
      }
    }
  },
  mounted(){
    this.locationDoneLoad()
    window.addEventListener('resize', this.resizeEvent)
  },
  destroyed(){
    window.removeEventListener('resize', this.resizeEvent)
  },
  methods: {
    // 获取hash，并设置激活项
    locationDoneLoad(){
      const pathname = window.location.pathname
      console.log('pathname', window.location.pathname)
      const result = this.navList.find(item => item.link === pathname)
      if (result) {
        result.selected = true
      }
      // 获取dom设置滑块位置
      this.$nextTick(() => {
        this.setSliding()
      })
    },
    floatEnter(param){
      if (param === 3) {
        this.isWhite = true
        this.showFloat = true
      }
    },

    floatLeave(param){
      if (param === 3) {
        this.isWhite = false
        this.showFloat = false
      }
    },
    mouseEnter(param){
      if (param.id == 3) {
        this.isWhite = true
        this.showFloat = true
      }
    },
    mouseLeave(param){
      if (param.id == 3) {
        this.isWhite = false
        this.showFloat = false
      }
    },
    // link点击
    navClick(param,e){
      this.navList.forEach(item => item.selected = false)
      const result = this.navList.find(item => item.id === param.id)
      console.log("导航点击时获取到的元素", result)
      if (result) {
        result.selected = true
        console.log(this.$refs.sliding)
        this.$nextTick(()=> {
          this.$refs.sliding.style.width = `${e.target.offsetWidth}px`
          this.$refs.sliding.style.left = `${e.target.offsetLeft}px`
        })
      }
    },
    floatClick(item){
      this.showFloat = false
      // this.$router.push(item.link)
      const { href } = this.$router.resolve(item.link)
      window.open( href, '_blank')

    },
    setSliding(){
        const active = document.getElementsByClassName('nav_active')[0]
        if (active) {
          this.$refs.sliding.style.width = `${active.offsetWidth}px`
          this.$refs.sliding.style.left = `${active.offsetLeft}px`
        }
    },
    resizeEvent(){
      this.setSliding()
    },
  },
  components: {
    Select
  }
}
